.wysiwyg {

    p, ul, ol {
        margin-bottom: calc((20/16) * 1em);
        &:last-child {
            margin-bottom: 0;
        }
    }

    a:not([class]) {
        @apply link;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        margin-bottom: 1.563em; // 25px
    }

    ul {
        list-style: disc outside;
        padding-left: 1.25em;
    }

    ol {
        list-style: decimal outside;
        padding-left: 1.25em;
    }

    li {
        display: list-item;
        padding-left: 0.5em;
    }

    ul li {
        list-style-type: disc;
    }

    em, i {
        font-style: italic;
    }

    strong, b {
        font-weight: bold;
    }

}

:root {
    --outer-margin: theme('outerMarginsMap.default');
    @screen s {
        --outer-margin: theme('outerMarginsMap.s');
    }
    @screen sp {
        --outer-margin: theme('outerMarginsMap.sp');
    }
    @screen m {
        --outer-margin: theme('outerMarginsMap.m');
    }
    @screen mp {
        --outer-margin: theme('outerMarginsMap.mp');
    }
    @screen l {
        --outer-margin: theme('outerMarginsMap.l');
    }
    @screen lp {
        --outer-margin: theme('outerMarginsMap.lp');
    }
    @screen xl {
        --outer-margin: theme('outerMarginsMap.xl');
    }
    --gutter: theme('gutterMap.default');
    @screen s {
        --gutter: theme('gutterMap.s');
    }
    @screen sp {
        --gutter: theme('gutterMap.sp');
    }
    @screen m {
        --gutter: theme('gutterMap.m');
    }
    @screen mp {
        --gutter: theme('gutterMap.mp');
    }
    @screen l {
        --gutter: theme('gutterMap.l');
    }
    @screen lp {
        --gutter: theme('gutterMap.lp');
    }
    @screen xl {
        --gutter: theme('gutterMap.xl');
    }
    
    --color-foreground: #000;
    --color-background: #fff;
    --color-background-alternate: #ffff80;
    --image-overlay-opacity: 0;
    
    &.dark {
        --color-foreground: #e0e0e0;
        --color-background: #000;
        --color-background-alternate: #000;
        --image-overlay-opacity: 0.15;
    }

    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-500 ease-out-sine;
}

html.no-js {
    img.lazyload {
        display: none;
    }
}

html.outline,
html.no-outline * {
    outline: none !important;
}

svg {
    display: block;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid:not(.blowout) {
// Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button {
    appearance: none;
    font-weight: normal;
    text-align: left;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none !important;
}

html.outline,
html.no-outline * {
    outline: none !important;
}

select {
    appearance: none;
}

input[class*="appearance-none"] {
    -webkit-appearance: none;
}

table {
    th, td {
        text-align: left;
        padding: 0;
        font-weight: normal;
        vertical-align: top;
    }
}

strong, b {
    font-weight: normal;
}

i, em {
    font-style: italic;
}

.hit::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

img, video, svg {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

details > summary {
    list-style: none;
    cursor: pointer;
}

details > summary > * {
    display: inline;
}

details > summary::-webkit-details-marker {
    display:none;
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
    * {
        animation-duration: 0.00001ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.00001ms !important;
        scroll-behavior: auto !important;
    }
}

.link {
    text-decoration-line: underline;
    text-underline-offset: 0.1em;
    transition: text-decoration-color 0.15s;
    
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            font-style: italic;
            text-underline-offset: 0.2em;
        }
    }
}

.squishy {
    transition: transform 0.1s linear;
    transform: scale(1);
    &:active {
        transform: scale(0.9);
    }
}

// Custom color mode
// These are all hacks; refactor to kjempelure CSS variables later
/*
html.bg-\[--bg\] *[class*="bg-"]:not([class*="opacity"]):not([class*="rounded"]) {
    background-color: var(--bg, #fff) !important;
}
html.bg-\[--bg\] #about {
    background-color: transparent !important;
}
html.bg-\[--bg\].dark {
    &, * {
        color: white !important;
    }
    *[class*="dark:opacity"] {
        opacity: 1 !important;
    }
    .hidden.dark\:block {
        display: none !important;
    }
}
html.bg-\[--bg\] .overlay {
    display: none !important;
}
*/

@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */
//.btn {
//    @apply bg-blue m:bg-red;
//}


    .emojiis {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 10000;
    }

    .emojii {
        font-size: 36px;
        line-height: 1;
        font-family: sans-serif;
        position: absolute;
        bottom: -40px;
        left: 10%;
        margin-left: -18px;
    }
    
    [data-kitten] {
        display: inline-block;
    }
    
    .hover-rotator {
        animation-name: rotator;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-play-state: paused;
        
        &:not(.selected):hover {
            animation-play-state: running;
        }
    } 
    
    @keyframes rotator { 
        from { 
            transform: rotate(0deg); 
        } to { 
            transform: rotate(360deg); 
        }
    }
}

.list {

    column-fill: balance;

    li {
        display: flex;
        padding-left: 1.5em;
        position: relative;
        line-height: 1.4;
    }

    li::before {
        content: "⬤";
        position: absolute;
        line-height: 0;
        left: 0;
        top: 0.55em;
    }

}
